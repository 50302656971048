const message = `<span style="word-break: break;">Prøv på nytt eller kontakt <a href="https://www.telenor.no/kundeservice/kontakt-oss/">kundeservice</a> hvis problemet vedvarer.</span>`;
const messageWithoutLink = `<span style="word-break: break;">Prøv på nytt eller kontakt kundeservice hvis problemet vedvarer.</span>`;
const codes = (error) => {
	let codes = "";
	codes += `</br>Error: ${
		error?.response?.data?.requestId ||
		error?.response?.data?.transactionId ||
		error?.response?.data?.message ||
		error?.response?.data?.title ||
		error?.response?.data?.description ||
		error?.response?.data?.httpMessage ||
		error?.message ||
		error?.errorMessage ||
		error?.description ||
		"Ukjent feil"
	}`;
	let code =
		error?.response?.data?.httpStatus ||
		error?.response?.data?.errorCode ||
		error?.response?.data?.status ||
		error?.response?.data?.code ||
		error?.code ||
		error?.errorCode;
	if (code) {
		codes += `</br>Code: ${code}`;
	}
	return `<span style="word-break: break-all;">${codes}</span>`;
};
const messageAndCodes = (error, disableLink) => {
	let messageAndCodes = disableLink ? messageWithoutLink + codes(error) : message + codes(error);
	return messageAndCodes;
};

export default { messageAndCodes, codes, message, messageWithoutLink };
